export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as any) === 'staging';
export const REACT_APP_API_BASE_URL = IS_DEV
  ? `http://localhost:${process.env.REACT_APP_SERVER_APP_PORT}`
  : process.env.REACT_APP_API_BASE_URL;
export const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
export const REACT_APP_ONLINE_LESSON_LEARNER_URL = IS_DEV
  ? `http://localhost:5000`
  : process.env.REACT_APP_ONLINE_LESSON_LEARNER_URL ?? '';
export const REACT_APP_COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID_FOR_LEARNER;
export const REACT_APP_COGNITO_USER_POOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID_FOR_LEARNER;
export const REACT_APP_SERVER_GMO_SHOP_ID = process.env.REACT_APP_SERVER_GMO_SHOP_ID;
export const REACT_APP_SERVER_GMO_TOKEN_URL = process.env.REACT_APP_SERVER_GMO_TOKEN_URL;
export const REACT_APP_TERMS_OF_SERVICE_URL = process.env.REACT_APP_TERMS_OF_SERVICE_URL;
export const REACT_APP_TERMS_OF_SERVICE_URL_EN = process.env.REACT_APP_TERMS_OF_SERVICE_URL_EN;
export const REACT_APP_TERMS_OF_SERVICE_URL_CN = process.env.REACT_APP_TERMS_OF_SERVICE_URL_CN;
export const REACT_APP_PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const REACT_APP_PRIVACY_POLICY_JAPAN_URL = process.env.REACT_APP_PRIVACY_POLICY_JAPAN_URL;
export const REACT_APP_PRIVACY_POLICY_ENGLISH_URL = process.env.REACT_APP_PRIVACY_POLICY_ENGLISH_URL;
export const REACT_APP_PRIVACY_POLICY_CHINESE_URL = process.env.REACT_APP_PRIVACY_POLICY_CHINESE_URL;
export const REACT_APP_SPECIFIED_COMMERCIAL_URL = process.env.REACT_APP_SPECIFIED_COMMERCIAL_URL;
export const REACT_APP_CHAT_WEBSOCKET_URL = process.env.REACT_APP_CHAT_WEBSOCKET_URL ?? '';

export const COOKIE_DOMAIN = IS_DEV ? 'localhost' : process.env.REACT_APP_LEARNER_URL!.match(/(?<=https:\/\/).*/)![0];
export const SERVER_WEBPUSH_PUBLIC_KEY = process.env.SERVER_WEBPUSH_PUBLIC_KEY;

// ====================
// AWS
// ====================
// TODO S3は無印の物をそのまま使うため、名称変更などは勝手にできない
export const S3_BUCKET_URL = 'https://sun-resource-tokyo.s3-ap-northeast-1.amazonaws.com/';
export const BASE_URL = 'https://sun-resource-tokyo.s3.ap-northeast-1.amazonaws.com/self_study/kana/audio/';
export const S3_FILE = {
  JSON: {
    LIST_SEASON_CHAPTER_SECTION: 'list_season_chapter_section_staging.json',
  },
};

// ====================
// Google
// ====================
/** タグマネージャーID */
export const GTM_ID = process.env.REACT_APP_GTM_ID ?? '';
export const TAG_MANAGET_ARGS = {
  gtmId: GTM_ID,
} as const;
